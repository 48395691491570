import titleLogo from "../assets/images/title-pl.png";
import viasatLogo from "../assets/images/viasat-explore-logo-pl.png";
import metaImage from '../assets/images/AGH-Share-1200x630-PL.jpg';
export default {
    site_url: 'https://viasatexplore-game.com/pl/',
    metaImage: metaImage,
    viasatLogo: viasatLogo,
    logo: titleLogo,
    quiz: 'Quiz',
    about: 'O programie',
    how_to_play: 'Instrukcja',
    ranking: 'Ranking',
    welcome_text: 'Sprawdź się w naszym quizie inspirowanym programem "Australijscy poszukiwacze złota: na ratunek kopalniom" na Polsat Viasat Explore.',
    welcome: 'Witaj!',
    episode: 'Odcinek',
    monday: 'poniedziałek',
    sunday: 'niedziela',
    take_a_quiz: 'Rozpocznij quiz',
    correct_answers: 'Prawidłowe odpowiedzi',
    total_time: 'Całkowity czas',
    correct_answer: 'Poprawna odpowiedź!',
    wrong_answer: 'Błędna odpowiedź!',
    next_question: 'NASTĘPNE PYTANIE',
    finish: 'ZAKOŃCZ',
    question: 'Pytanie',
    you: 'Twój wynik',
    login_text: 'Wpisz swój nick poniżej i wciśnij start, aby rozpocząć.',
    about_text_1: 'Australię ogarnia prawdziwa gorączka złota: poszukiwacze cennego kruszcu ścigają się, chcąc zdobyć fortuny. Niestety, udaje się to tylko niektórym, podczas gdy inni mają duże problemy z urzeczywistnieniem marzeń.',
    about_text_2: 'Australijscy poszukiwacze złota: na ratunek kopalniom',
    about_text_3: 'PREMIERA SEZONU',
    about_text_4: 'Walczą z problemami technicznymi, nie mogą znaleźć złota, brakuje im podstawowej wiedzy na temat jego wydobywania i umiejętności niezbędnych do uniknięcia finansowej katastrofy.',
    about_text_5: 'Weteran programu „Australijscy poszukiwacze złota", Paul Mackie, wraz ze swoim zespołem stara się pomóc takim niedoświadczonym przedsiębiorcom w pokonywaniu problemów i wypracowaniu zysków. Paul zna się na rzeczy: potrafi doradzić każdemu i zaproponować bardzo nietypowe rozwiązania wielu problemów. Do jego ekipy dołączają także doświadczony geolog Aaron Raddock i specjalistka w dziedzinie logistyki Melanie Wood.',
    about_text_6: 'Australijscy poszukiwacze złota: na ratunek kopalniom',
    about_text_7: 'POWTÓRKI ODCINKÓW',
    about_text_8: 'Ekipa podróżuje do najtrudniejszych i najdzikszych rejonów Australii i starają się pomóc sześciu zmagającym się z problemami właścicielom kopalni złota.',
    about_text_9: 'Mają tylko sześć dni na dokonanie niezbędnych napraw, zlokalizowanie nowych złóż złota, podniesienie umiejętności właścicieli i remonty zepsutych urządzeń. Ich misją jest nie tylko pokonanie technicznych problemów, ale także zmiana mentalności i umiejętności ludzi, którzy chcą się wzbogacić na wydobywaniu złota.',
    how_to_play_text_1: 'Ten quiz jest inspirowany programem "Australijscy poszukiwacze złota: na ratunek kopalniom", który możesz oglądać na kanale Polsat Viasat Explore w każdy poniedziałek w maju o godzinie 21:00.',
    how_to_play_text_2: 'Przed rozpoczęciem quizu prosimy podać swoje imię lub pseudonim, żeby móc później sprawdzić swój wynik.',
    how_to_play_text_3: 'Quiz składa się z ogólnych pytań dotyczących złota oraz kilku pytań dotyczących lokalnego wydobywania złota. Twój czas odpowiedzi na każde pytanie jest ograniczony. Liczba poprawnych odpowiedzi i czas poświęcony na ich udzielenie są sumowane - po zakończeniu quizu możesz sprawdzić swoją pozycję w tabeli wyników.',
    how_to_play_text_4: 'Wierzymy, że dowiesz się tu czegoś nowego, a jeśli chcesz na własne oczy zobaczyć, jak profesjonalnie "polować" na złoto, oglądaj program "Australijscy poszukiwacze złota: na ratunek kopalniom" na Polsat Viasat Explore w każdy poniedziałek w maju o 21:00. Poszukaj naszego kanału u swojego dostawcy telewizji kablowej.',
    how_to_play_text_5: 'Za rozwiązanie quizu nie są przewidziane nagrody.',
    save: 'Zapisz',
    cookies_popup_text: 'Nasza strona internetowa wykorzystuje pliki cookie, aby zrozumieć, w jaki sposób użytkownicy korzystają z naszej witryny, abyśmy mogli ją ulepszać. Zaznaczając odpowiednie pole w naszych wyskakujących oknach Analytics, wyrażasz zgodę na używanie przez nas plików cookie zgodnie z niniejszą Polityką plików cookie. Jeśli nie wyrażasz zgody na korzystanie z plików cookie w operacjach sieciowych, możesz je zablokować lub wyłączyć za pomocą ustawień przeglądarki, odznaczając dostępne pole wyboru, określone w niniejszej Polityce plików cookie. Jednak zablokowanie wszystkich plików cookie może mieć negatywny wpływ na korzystanie z tej witryny.',
}
