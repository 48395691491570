import titleLogo from '../assets/images/title-en.png';
import viasatLogo from '../assets/images/viasat-explore-logo.png';
import metaImage from '../assets/images/AGH-Share-1200x630-SRB.jpg';
export default {
    site_url: 'https://viasatexplore-game.com/en/',
    metaImage: metaImage,
    viasatLogo: viasatLogo,
    logo: titleLogo,
    quiz: 'Quiz',
    about: 'About the Show',
    how_to_play: 'Quiz',
    ranking: 'Ranking',
    welcome_text: 'Welcome to our quiz inspired by "Aussie Gold Hunters Mine SOS" series on Viasat Explore channel.',
    welcome: 'Welcome to',
    episode: 'Episode',
    monday: 'Mon',
    sunday: 'Sun',
    take_a_quiz: 'Take a Quiz',
    correct_answers: 'Correct Answers',
    total_time: 'Total Time',
    correct_answer: 'Correct Answer!',
    wrong_answer: 'Wrong Answer!',
    next_question: 'Next Question',
    finish: 'Finish',
    question: 'Question',
    you: 'YOU',
    login_text: 'Enter your nickname below and press start when you are ready.',
    about_text_1: 'As a modern-day gold rush sweeps Australia, prospectors are racing to strike it rich, but while a few cash in, many are struggling to turn their gold mining dreams into reality.',
    about_text_2: 'Aussie Gold Hunters - Mine SOS',
    about_text_3: 'SEASON 1 PREMIERE',
    about_text_4: 'Battling mechanical miseries and epic gold droughts, they\'re missing the mining know-how to rescue their fortunes from disaster.',
    about_text_5: 'However, veteran Aussie Gold Hunter Paul Mackie and his mine makeover team are here to help turn prospectors\' pain back into profit; armed with his signature straight-shooting advice and scrapyard solutions, Paul is joined by master bush geologist Aaron Raddock and his \'pit boss\' - logistics expert Melanie Wood.',
    about_text_6: 'Aussie Gold Hunters - Mine SOS',
    about_text_7: 'SEASON 1 RERUN',
    about_text_8: 'The team travel together to Australia\'s toughest wild frontiers to help restore six struggling gold mining operations to success.',
    about_text_9: 'With just six days to make mechanical fixes, locate new gold deposits, upgrade skills and overhaul broken-down camp facilities, the crew are on a mission to transform gold mines - and miners - as they dig deep to help those in need.',
    how_to_play_text_1: 'This quiz is inspired by Aussie Gold Hunters Mine SOS series, that you can watch on Viasat Explore  TV channel every Monday in May at 21.00.',
    how_to_play_text_2: 'Before you start the quiz it is important to enter your name or nickname, so you could check your score later. ',
    how_to_play_text_3: 'The quiz consists of general knowledge questions about gold and of some localised gold mining questions. Your answering time for each question is limited. The number of correct answers and time spent on answering are add together and you can check your position on the score table after you finish the quiz.',
    how_to_play_text_4: 'TWe believe that you will learn something new and if you want to witness first-hand how to hunt for gold, we recommend you tune in for Aussie Gold Hunters Mine SOS series on Viasat Explore every Monday in May at 21.00. Look for our channel at your cable TV provider.',
    how_to_play_text_5: 'The quiz is not prize-winning.',
    save: 'Save',
    cookies_popup_text: 'Our website uses cookies to understand how people use our website in order for us to improve the website. By ticking the box of our popup Analytics windows, you hereby consent to our use of cookies as defined in this Cookies Policy. If you do not consent to the use of Web Operations Cookies, you may block or disable them using your browser settings and by unchecking the available checkbox, the opt-out links set out in this Cookies Policy. However, blocking all cookies may have a negative impact on your use of this website.',
}
