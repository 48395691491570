import titleLogo from "../assets/images/title-sr.png";
import viasatLogo from "../assets/images/viasat-explore-logo.png";
import metaImage from '../assets/images/AGH-Share-1200x630-SRB.jpg';
export default {
    site_url: 'https://viasatexplore-game.com/sr/',
    metaImage: metaImage,
    viasatLogo: viasatLogo,
    logo: titleLogo,
    quiz: 'Kviz',
    about: 'O emisiji',
    how_to_play: 'O kvizu',
    ranking: 'Rang lista',
    welcome_text: 'Dobrodošli u naš kviz koji je inspirisan emisijom "Australijski Lovci Na Zlato: Pomoć Rudnicima" koja se emituje na kanalu Viasat Explore.',
    welcome: 'Dobrodošli u',
    episode: 'Epizoda',
    monday: 'pon.',
    sunday: 'ned.',
    take_a_quiz: 'Započni kviz',
    correct_answers: 'Tačnih odgovora',
    total_time: 'Ukupno vreme',
    correct_answer: 'Tačan odgovor!',
    wrong_answer: 'Netačan odgovor!',
    next_question: 'Sledeće pitanje',
    finish: 'Završi',
    question: 'Pitanje',
    you: 'TI',
    login_text: 'Upiši svoje ime u polju ispod i pritisni dugme start kada budeš spreman.',
    about_text_1: 'Dok moderna zlatna groznica trese Australiju, kopači se utrkuju ko će se obogatiti. Mnogi se bore da svoje snove pretvore u stvarnost.',
    about_text_2: 'Australijski lovci na zlato: pomoć rudnicima',
    about_text_3: 'PREMIJERA PRVE SEZONE',
    about_text_4: 'U borbi sa mehaničkim nevoljama i velikim zlatnim sušama, kopačima nedostaje rudarsko iskustvo.',
    about_text_5: 'Međutim, veteran Pol Meki i njegov tim su tu da pomognu da se problemi i poteškoće kopača ponovo pretvore u profit. Naoružanom svojim savetima, Polu se pridružuju glavni geolog Eron Radok i njegov "šef jame" i ekspert za logistiku, Melani Vud.',
    about_text_6: 'Australijski lovci na zlato: pomoć rudnicima',
    about_text_7: 'REPRIZA PRVE SEZONE',
    about_text_8: 'Tim zajedno putuje do najtežih predela Australije, kako bi pomogao da se šest problematičnih rudarskih operacija vrati na pravi kolosek. ',
    about_text_9: 'Sa samo šest dana za mehaničke popravke, lociranje novih nalazišta zlata, nadogradnju veština i remont pokvarenih kampova, posada je na misiji da sredi i transformiše rudnike zlata, ali i same rudare.',
    how_to_play_text_1: 'Ovaj kviz je zabavnog karaktera, inspirisan sjajnom emisijom Australijski lovci na zlato: pomoć rudnicima, koja se emituje na TV kanalu Viasat Explore, svakog ponedeljka tokom maja, u 21.00.',
    how_to_play_text_2: 'Pre nego se kviz pokrene, važno je upisati svoje ime ili nadimak, kako bi se rezultati prikazali na rang listi.',
    how_to_play_text_3: 'Tokom kviza dobijaš opšta pitanja vezana za zlato i lokalizovana pitanja o nalazištima. Svako pitanje ima ograničeno vreme za odgovor. Broj tačnih odgovora i potrošeno vreme tokom kviza se sabiraju, a svoju poziciju možeš pronaći na rang listi nakon kviza.',
    how_to_play_text_4: 'Verujemo da ćeš naučiti nešto novo igrajući se sa nama, a ukoliko želiš da vidiš kako izgleda tražiti zlato iz prve ruke, predlažemo ti da pogledaš emisiju Australijski lovci na zlato: pomoć rudnicima. Emituje se na TV kanalu Viasat Explore, svakog ponedeljka tokom maja, u 21.00. Potraži nas kod svog kablovskog operatera.',
    how_to_play_text_5: 'Kviz nije nagradnog karaktera.',
    save: 'Sačuvaj',
    cookies_popup_text: 'Naš sajt koristi kolačiće kako bi što bolje razumeli korišćenje sajta i unapredili ga.Čekiranjem kvadrata na prozoru analitika, pristajete na korišćenje kolačića u skladu sa ovom politikom upotrebe kolačića. Ako ne želite da prihvatite upotrebu kolačića, možete ih blokirati ili isključiti u podešavanjima na vašem pretraživaču i odabrati da ne koristite veze definisane ovom politikom upotrebe kolačića. Međutim, ukoliko se onemoguće kolačići može doći do toga da usluge koje se pružaju na sajtu neće tako dobro funkcionisati.',
}
