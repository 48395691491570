import titleLogo from "../assets/images/title-hr.png";
import viasatLogo from "../assets/images/viasat-explore-logo.png";
import metaImage from '../assets/images/AGH-Share-1200x630-HR.jpg';
export default {
    site_url: 'https://viasatexplore-game.com/hr/',
    metaImage: metaImage,
    viasatLogo: viasatLogo,
    logo: titleLogo,
    quiz: 'Kviz',
    about: 'O emisiji',
    how_to_play: 'O kvizu',
    ranking: 'Rang ljestvica',
    welcome_text: 'Dobrodošli u naš kviz, inspiriran emisijom "Australski lovci na zlato: SOS za rudnik" koja se prikazuje na programu Viasat Explore.',
    welcome: 'Dobrodošli u',
    episode: 'Epizoda',
    monday: 'pon.',
    sunday: 'ned.',
    take_a_quiz: 'Započni s kvizom',
    correct_answers: 'Točnih odgovora',
    total_time: 'Ukupno vrijeme',
    correct_answer: 'Točan odgovor!',
    wrong_answer: 'Netočan odgovor!',
    next_question: 'Sljedeće pitanje',
    finish: 'Završi',
    question: 'Pitanje',
    you: 'TI',
    login_text: 'Unesi svoj nadimak i odaberi START kad budeš spreman',
    about_text_1: 'Dok suvremena zlatna groznica hara Australijom, kopači zlata se utrkuju i muku muče kako bi ostvarili snove o pronalasku zlata i bogatstvu.',
    about_text_2: 'Australski lovci na zlato: SOS za rudnik',
    about_text_3: 'PREMIJERA PRVE SEZONE',
    about_text_4: 'Hrvajući se s mehaničkim problemima i neviđenim zlatnim sušama, nedostaje im znanja o rudarenju kako bi izbjegli katastrofu.',
    about_text_5: 'No zato su tu iskusni australski lovac na zlato Paul Mackie i njegov tim za rudnike, kako bi kopačima zlata pomogli da svoje muke pretvore u zaradu. Paulu, oboružanom karakterističnim izravnim savjetima i rješenjima s otpada, pridružuju se vrsni geolog Aaron Raddock i njegova „šefica rudnika” – stručnjakinja za logistiku Melanie Wood.',
    about_text_6: 'Australski lovci na zlato: SOS za rudnik',
    about_text_7: 'REPRIZA PRVE SEZONE',
    about_text_8: 'Tim zajedno obilazi najsurovije predjele Australije kako bi pomogao da šest posrnulih operacija iskapanja zlata opet postane uspješno.',
    about_text_9: 'Uz samo šest dana za mehaničke popravke, lociranje novih nalazišta zlata, poboljšanje vještina i obnovu objekata logora, ekipa je na misiji preobrazbe rudnika zlata, ali i rudara, kopajući duboko kako bi pomogli onima kojima je pomoć potrebna.',
    how_to_play_text_1: 'Ovaj kviz je zabavnog karaktera, a inspiriran je emisijom „Australski lovci na zlato: SOS za rudnik”, koja se prikazuje na televizijskom programu Viasat Explore, svakog ponedjeljka u svibnju od 21:00 sat.',
    how_to_play_text_2: 'Prije započinjanja s kvizom, važno je da upišete svoje ime ili nadimak, kako biste kasnije mogli provjeriti svoj rezultat.',
    how_to_play_text_3: 'Kviz se sastoji od općih pitanja o zlatu i pitanja o lokalnim nalazištima zlata. Vrijeme odgovaranja na svako pitanje je ograničeno. Broj točnih odgovora i vrijeme utrošeno na odgovaranje se zbrajaju, a svoju poziciju možete provjeriti na rang ljestvici nakon završetka kviza.',
    how_to_play_text_4: 'Vjerujemo da ćete naučiti nešto novo igrajući se s nama, a ako želite iz prve ruke svjedočiti potrazi za blagom, gledajte emisiju „Australski lovci na zlato: SOS za rudnik”, svakog ponedjeljka u svibnju. Naš program potražite kod vašeg operatora.',
    how_to_play_text_5: 'Kviz nije nagradnog karaktera.',
    save: 'Spremi odabir',
    cookies_popup_text: 'Ova stranica koristi kolačiće kako bismo što bolje razumjeli korištenje stranice i kako bismo ju poboljšali. Označavanjem kvadratića u prozoru za analitiku pristajete na korištenje kolačića u skladu s ovom politikom upotrebe kolačića. Ako ne pristajete na korištenje kolačića, možete ih blokirati ili isključiti u postavkama vašeg preglednika, poništavanjem potvrdnog okvira, birajući da ne koristite veze definirane ovom politikom upotrebe kolačića. Međutim, onemogućivanje svih kolačića može dovesti do toga da usluge koje se pružaju na ovoj stranici ne funkcioniraju dobro.',
}
