import titleLogo from "../assets/images/title-ro.png";
import viasatLogo from "../assets/images/viasat-explore-logo.png";
import metaImage from '../assets/images/AGH-Share-1200x630-RO.jpg';
export default {
    site_url: 'https://viasatexplore-game.com/ro/',
    metaImage: metaImage,
    viasatLogo: viasatLogo,
    logo: titleLogo,
    quiz: 'Test',
    about: 'Despre emisiune',
    how_to_play: 'Cum să joci',
    ranking: 'Clasament',
    welcome_text: 'Bine ați venit la testul nostru, inspirat de serialul "Căutătorii de aur din Australia: Mineri la ananghie", de pe postul Viasat Explore.',
    welcome: 'Bine ați venit la',
    episode: 'Episodul',
    monday: 'luni',
    sunday: 'duminică',
    take_a_quiz: 'Dați un test',
    correct_answers: 'Răspunsuri corecte',
    total_time: 'TOTAL TIMP',
    correct_answer: 'Răspuns corect!',
    wrong_answer: 'Răspuns greșit!',
    next_question: 'ÎNTREBAREA URMĂTOARE',
    finish: 'Termină',
    question: 'ÎNTREBAREA URMĂTOARE',
    you: 'TU',
    login_text: 'Introduceți porecla dvs. de mai jos și apăsați start când sunteți gata.',
    about_text_1: 'În timp ce o adevărată goană după aur se desfășoară în Australia, căutătorii de aur se întrec în a se îmbogăți, dar, în timp ce câțiva dintre ei câștigă bani, mulți se luptă pentru a-și transforma visele de descoperire a aurului în realitate.',
    about_text_2: 'Căutătorii de aur din Australia: Mineri la ananghie',
    about_text_3: 'PREMIERA SEZONULUI 1',
    about_text_4: '',
    about_text_5: 'În Australia zilelor noastre a început o „goană după aur" modernă. Căutătorii de aur speră să dea lovitura și să se îmbogățească. Puțini reușesc cu adevărat, dar mulți trudesc din greu ca să-și transforme în realitate visurile aurite. Înfruntând probleme tehnice și zone „secătuite" de aur, unii nu știu ce trebuie să facă pentru a-și salva de la dezastru munca și avuția. Aici intervin Paul Mackie, un veteran al căutării de aur din Australia, și echipa lui de specialiști în domeniu, gata să-i ajute pe mineri să-și transforme durerea în dolari. Înarmat cu o experiență solidă, sfaturi clare și soluții ingenioase, Paul lucrează alături de Aaron Raddock, expert în geologia zonei, și Melanie Wood, specialistă în logistică și „șefă de șantier"',
    about_text_6: 'Căutătorii de aur din Australia: Mineri la ananghie',
    about_text_7: 'SEZONUL 1 RELUARE',
    about_text_8: 'Echipa se îndreaptă spre cele mai dure frontiere sălbatice ale Australiei pentru a ajuta la refacerea a șase exploatări miniere de aur aflate în dificultate.',
    about_text_9: 'Având la dispoziție doar șase zile pentru a face reparații mecanice, pentru a localiza noi zăcăminte de aur, pentru a îmbunătăți calificările minerilor și pentru a reface instalațiile de tabără stricate, echipa are misiunea de a transforma minele de aur și minerii, în timp ce sapă adânc pentru a-i ajuta pe cei aflați în nevoie.',
    how_to_play_text_1: 'Acest test este inspirat din serialul „Căutătorii de aur din Australia: Mineri la ananghie”, pe care îl puteți urmări la TV pe postul Viasat Explore, în fiecare zi de luni din luna mai, de la ora 22:00.',
    how_to_play_text_2: 'Înainte de a începe testul este important să vă introduceți numele sau porecla, pentru a vă putea verifica scorul mai târziu.',
    how_to_play_text_3: 'Testul constă în întrebări de cunoștințe generale despre aur și de câteva întrebări specifice din domeniul extracției aurului. Timpul de răspuns pentru fiecare întrebare este limitat. Numărul de răspunsuri corecte și timpul petrecut pentru a răspunde vor determina scorul și vă puteți verifica locul din clasament, după ce veți termina testul.',
    how_to_play_text_4: 'Suntem convinși că veți învăța ceva nou și dacă doriți să fiți martor direct la modul în care se caută aur, vă recomandăm să urmăriți serialul „Căutătorii de aur din Australia: Mineri la ananghie”, pe Viasat Explore, în fiecare zi de luni din luna mai, de la ora 22:00. Căutați postul nostru la furnizorul dvs. de televiziune prin cablu.',
    how_to_play_text_5: 'Completarea testului nu va fi urmată de acordarea unor premii.',
    save: 'Salvați',
    cookies_popup_text: 'Site-ul nostru web utilizează cookie-uri pentru a înțelege modul în care oamenii folosesc site-ul nostru web, pentru a ne permite să îmbunătățim site-ul web. Prin bifarea căsuței din ferestrele noastre pop-up de analiză, vă dați acordul pentru utilizarea cookie-urilor așa cum sunt definite în această Politică privind cookie-urile. Dacă nu sunteți de acord cu utilizarea modulelor cookie pentru operațiuni web, le puteți bloca sau dezactiva utilizând setările browserului dvs. și debifând căsuța de selectare disponibilă, linkurile de dezactivare stabilite în această Politică privind modulele cookie. Cu toate acestea, blocarea tuturor cookie-urilor poate avea un impact negativ asupra utilizării de către dvs. a acestui site web',
}
